<template>
  <div>
    <div class="greyBackground"></div>
    <v-card class="greyColor" elevation="0">
      <h2 class="faledia-caption">Aufgabenbeziehungen nutzen</h2>
      <v-card outlined>
        <v-card-title>Was sollen Schüler:innen lernen?</v-card-title>
        <v-card-text
          >Die Kinder können...
          <ul>
            <li>
              Beziehungen zwischen Aufgaben und Rechenoperationen benennen
            </li>
            <li>
              die Erklärung einer anderen Person zu Beziehungen zwischen
              Aufgaben und Rechenoperationen wiedergeben
            </li>
            <li>
              Beziehungen zwischen Aufgaben und Rechenoperationen erklären
            </li>
            <li>
              mithilfe von Darstellungen in verschiedenen Darstellungsformen
              Beziehungen zwischen Aufgaben und Rechenoperationen darstellen
              (z.B. mit Plättchen, Forschermitteln o.ä.)
            </li>
            <li>
              Zusammenhänge zwischen Aufgaben und Rechenoperationen zum
              flexiblen Rechnen nutzen
            </li>
          </ul></v-card-text
        >
      </v-card>

      <br />

      <h3 class="faledia-caption">
        Was sollten Sie zum Nutzen von Aufgabenbeziehungen wissen?
      </h3>
      <div>
        <p>
          Als dritte Komponente ist die Nutzung von Beziehungen – einerseits
          zwischen Aufgaben, andererseits aber auch zwischen den
          Rechenoperationen – von besonderer Bedeutung für die Entwicklung eines
          tragfähigen Operationsverständnisses. Ohne diese können sich sichere
          und flexible Kompetenzen im Rechnen nicht entwickeln.
        </p>
        <p>
          Zum Rechnen allgemein und insbesondere zum Erlernen des schnellen
          Kopfrechnens ist es wichtig, Beziehungen zwischen Aufgaben
          herzustellen bzw. zu sehen, um die Ergebnisse bereits beherrschter
          Aufgaben zur Berechnung anderer Aufgaben zu nutzen. Es ist natürlich
          am Ende des Lernprozesses eine Automatisierung des kleinen Einmaleins
          ein Ziel. Das sollte aber nicht zu früh angebahnt werden, um dem
          beziehungsreichen Lernen und Abteilen von Aufgaben den nötigen
          Stellenwert zu schreiben zu können. Dabei spielen Rechengesetze der
          elementaren Arithmetik eine besondere Rolle.
        </p>
        <p>
          Um sich genauer damit zu beschäftigen, wie Kinder Aufgaben nutzen und
          welche Rechengesetze und Zusammenhänge dahinterstecken, hat Mia bei
          einigen Aufgaben erklärt, wie sie vorgeht. Dazu wird in einem Beispiel
          eine Erläuterung gegeben, das nächste Beispiel können Sie sich selbst
          genauer erarbeiten:
        </p>
        <div style="background-color: white">
          <div class="lbst-border">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>
                      <!-- Audio File 13+9-->
                      <v-row>
                        <v-col class="text-center" align-self="center">
                          <vuetify-audio
                            ref="audioPlayer5"
                            :file="
                              require('@/assets/ove/Stempeln/mia_13plus9.mp4')
                            "
                            color="primary"
                            flat
                            :ended="
                              () => audioEnded('Stempeln/mia_13plus9.mp4')
                            "
                            @click.native="
                              () =>
                                audioPlayerClicked(
                                  $event,
                                  'Stempeln/mia_13plus9.mp4',
                                  $refs.audioPlayer5
                                )
                            "
                            class="audioPlayerBigger"
                          ></vuetify-audio>
                          13+9 und 38+7
                        </v-col>
                        <v-col style="text-align: justify">
                          Jetzt haben Sie gehört wie Mia zwei Additionsaufgaben
                          löst. Im Folgenden erklären wir Ihnen nochmal
                          ausführlicher für das Beispiel 15+8, wie Mia denkt und
                          warum sie so rechnen darf. <br />
                          Die Aufgabe 15+8 hat Mia mit Hilfe des
                          Assoziativgesetz gelöst, also (a+b)+c = a +(b+c). Das
                          heißt Mia hat den zweiten Summanden so zerlegt, dass
                          der Zehnerübergang erleichtert wird, also aus 8 wird
                          5+3 und hat dann 15 mit 5 addiert und zu der 20
                          addiert sie anschließend 3 und erhält 23. <br />
                          Durch die Zerlegung eines Summanden kann der
                          Zehnerübergang bei einigen Aufgaben erleichtert
                          werden, dabei ist es irrelevant, welcher Summand
                          zerlegt wird.
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>

        <p>
          Nun hören Sie bitte zunächst Mias Beschreibung ihrer Rechnung.
          Anschließend werden auf unterschiedlichen Ebenen Fragen gestellt, die
          Sie beantworten sollten, um eine Rückmeldung zu erhalten.
        </p>
        <!-- 19+21 -->
        <div style="background-color: white">
          <LernbausteinStempeln
            :id="LBSTStempeln[0].id"
            :workOrder="LBSTStempeln[0].workOrder"
            :mediaDescription="LBSTStempeln[0].mediaDescription"
            :image="LBSTStempeln[0].image"
            :audio="LBSTStempeln[0].audio"
            :elements="LBSTStempeln[0].elements"
          >
            <template v-slot:alternative-content-0>
              <div class="my-3">Vermutlich wird Mia 15+20 rechnen.</div>
            </template>

            <template v-slot:alternative-content-1>
              Jetzt haben Sie gehört wie Mia zwei Additionsaufgaben löst. Im
              Folgenden erklären wir Ihnen nochmal ausführlicher für das
              Beispiel 17+18, wie Mia denkt und warum sie so rechnen darf.<br />
              Die Aufgabe 17+18 hat Mia auch mit Hilfe eines Konstanzgesetzes –
              hier das Konstanzgesetz der Summe – gelöst, also a+b = (a±c) +
              (b∓c). Das heißt Mia hat die 17 um 2 vermindert und kommt so auf
              15 und die 18 um 2 erhöht und kommt damit auf 20, zum Schluss hat
              sie dann beide errechneten Zahlen, 15 und 20, addiert und erhält
              35.<br />
              Durch die gegensinnige Veränderung beider Summanden wird eine
              leichtere Aufgabe erzeugt, da durch einen glatten Zehner der
              Zehnerübergang umgangen wird.
            </template>

            <template v-slot:alternative-content>
              Jetzt haben Sie gehört wie Mia zwei Additionsaufgaben löst. Im
              Folgenden erklären wir Ihnen nochmal ausführlicher für das
              Beispiel 17+18, wie Mia denkt und warum sie so rechnen darf.<br />
              Die Aufgabe 17+18 hat Mia auch mit Hilfe eines Konstanzgesetzes –
              hier das Konstanzgesetz der Summe – gelöst, also a+b = (a±c) +
              (b∓c). Das heißt Mia hat die 17 um 2 vermindert und kommt so auf
              15 und die 18 um 2 erhöht und kommt damit auf 20, zum Schluss hat
              sie dann beide errechneten Zahlen, 15 und 20, addiert und erhält
              35.<br />
              Durch die gegensinnige Veränderung beider Summanden wird eine
              leichtere Aufgabe erzeugt, da durch einen glatten Zehner der
              Zehnerübergang umgangen wird.
            </template>
          </LernbausteinStempeln>
        </div>

        <AppButtonCollapse>
          <!-- 11+178: Bisher keine I-Alternative -->
          <div style="background-color: white">
            <LernbausteinStempeln
              :id="LBSTStempeln[1].id"
              :workOrder="LBSTStempeln[1].workOrder"
              :mediaDescription="LBSTStempeln[1].mediaDescription"
              :image="LBSTStempeln[1].image"
              :audio="LBSTStempeln[1].audio"
              :elements="LBSTStempeln[1].elements"
            >
              <template v-slot:alternative-content-0>
                <div class="my-3">Vermutlich wird Mia 144+17 rechnen.</div>
              </template>

              <template v-slot:alternative-content-1>
                <vuetify-audio
                  ref="audioPlayer5"
                  :file="
                    require('@/assets/ov/Stempeln_Hintergrund/OVI_OABH2_1_Stufe02.mp3')
                  "
                  color="primary"
                  flat
                  :ended="
                    () =>
                      audioEnded('Stempeln_Hintergrund/OVI_OABH2_1_Stufe02.mp3')
                  "
                  @click.native="
                    () =>
                      audioPlayerClicked(
                        $event,
                        'Stempeln_Hintergrund/OVI_OABH2_1_Stufe02.mp3',
                        $refs.audioPlayer5
                      )
                  "
                  class="audioPlayerBigger"
                ></vuetify-audio>
              </template>

              <template v-slot:alternative-content>
                <vuetify-audio
                  ref="audioPlayer5"
                  :file="require('@/assets/ovi/Drehbuecher/OVI_OABH2_1.mp3')"
                  color="primary"
                  flat
                  :ended="() => audioEnded('Drehbuecher/OVI_OABH2_1.mp3')"
                  @click.native="
                    () =>
                      audioPlayerClicked(
                        $event,
                        'Drehbuecher/OVI_OABH2_1.mp3',
                        $refs.audioPlayer5
                      )
                  "
                  class="audioPlayerBigger"
                ></vuetify-audio>
              </template>
            </LernbausteinStempeln>
          </div>

          <AppButtonCollapse>
            <div style="background-color: white">
              <LernbausteinStempeln
                :id="LBSTStempeln[2].id"
                :workOrder="LBSTStempeln[2].workOrder"
                :mediaDescription="LBSTStempeln[2].mediaDescription"
                :image="LBSTStempeln[2].image"
                :audio="LBSTStempeln[2].audio"
                :elements="LBSTStempeln[2].elements"
              >
                <template v-slot:alternative-content-0>
                  <div class="my-3">Vermutlich wird Mia 9⋅5+9⋅1 rechnen.</div>
                </template>

                <template v-slot:alternative-content-1>
                  Mia nutzt hier die Zerlegung des Multiplikators in 5 plus 4,
                  da ihr die Kernaufgaben mit Fünf vermutlich leichter
                  erscheinen. Daraus kann sie folglich – mit Hilfe des
                  Distributivgesetzes – die schwierigere Aufgabe aus leichteren
                  Aufgaben ableiten.
                </template>

                <template v-slot:alternative-content>
                  Jetzt haben Sie gehört wie Mia zwei Multiplikationsaufgaben
                  löst. Im Folgenden erklären wir Ihnen nochmal ausführlicher
                  für das Beispiel 6·9, wie Mia denkt und warum sie so rechnen
                  darf.<br />
                  Die Aufgabe 6·9 hat Mia auch mit Hilfe des Distributivgesetzes
                  also a·b+a·c = a · (b+c) gelöst. Das heißt sie hat die 6 in
                  (5+1) zerlegt und beide Summanden jeweils mit 9 multipliziert.
                  Dann hat sie beide Zwischenergebnisse, 45 und 9 mit einander
                  addiert und kommt so auf das richtige Ergebnis 54.<br />
                  Die Zerlegung kann das Berechnen von einfacheren Aufgaben zur
                  Folge haben und ist somit sinnvoll, um nicht nur das kleine
                  Einmaleins auswendig zu lernen, sondern beziehungsreich und
                  somit anschlussfähig zu lernen. Der Multiplikator oder der
                  Multiplikand kann also zerlegt werden, sodass einfachere
                  Aufgaben entstehen.
                </template>
              </LernbausteinStempeln>
            </div>

            <AppButtonCollapse>
              <!-- 12*5 -->
              <div style="background-color: white">
                <LernbausteinStempeln
                  :id="LBSTStempeln[3].id"
                  :workOrder="LBSTStempeln[3].workOrder"
                  :mediaDescription="LBSTStempeln[3].mediaDescription"
                  :image="LBSTStempeln[3].image"
                  :audio="LBSTStempeln[3].audio"
                  :elements="LBSTStempeln[3].elements"
                >
                  <template v-slot:alternative-content-0>
                    <div class="my-3">Vermutlich wird Mia 8⋅8 rechnen.</div>
                  </template>

                  <template v-slot:alternative-content-1>
                    <vuetify-audio
                      ref="audioPlayer5"
                      :file="
                        require('@/assets/ov/Stempeln_Hintergrund/OVI_OABH2_2_Stufe02.mp3')
                      "
                      color="primary"
                      flat
                      :ended="
                        () =>
                          audioEnded(
                            'Stempeln_Hintergrund/OVI_OABH2_2_Stufe02.mp3'
                          )
                      "
                      @click.native="
                        () =>
                          audioPlayerClicked(
                            $event,
                            'Stempeln_Hintergrund/OVI_OABH2_2_Stufe02.mp3',
                            $refs.audioPlayer5
                          )
                      "
                      class="audioPlayerBigger standAloneAudio"
                    ></vuetify-audio>
                  </template>

                  <template v-slot:alternative-content>
                    <vuetify-audio
                      ref="audioPlayer5"
                      :file="
                        require('@/assets/ovi/Drehbuecher/OVI_OABH2_2.mp3')
                      "
                      color="primary"
                      flat
                      :ended="() => audioEnded('Drehbuecher/OVI_OABH2_2.mp3')"
                      @click.native="
                        () =>
                          audioPlayerClicked(
                            $event,
                            'Drehbuecher/OVI_OABH2_2.mp3',
                            $refs.audioPlayer5
                          )
                      "
                      class="audioPlayerBigger standAloneAudio"
                    ></vuetify-audio>
                  </template>
                </LernbausteinStempeln>
              </div>
            </AppButtonCollapse>
          </AppButtonCollapse>
        </AppButtonCollapse>

        <p>
          Zur Übersicht dient die folgende Tabelle, die die Gesetze und
          Zusammenhänge, die Sie gerade in der Aktivität schon angewendet haben,
          noch einmal übersichtlich darstellt.
        </p>
      </div>

      <div style="background-color: white">
        <v-row>
          <v-col cols="gesetzeTabelleFormat">
            <!-- Kommutativgesetze-Tabelle -->
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr style="text-align: center">
                    <td colspan="2"><strong>Kommutativgesetz</strong></td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Addition: </strong>Die Summanden einer
                      Additionsaufgabe können vertauscht werden, ohne dass sich
                      dabei das Ergebnis ändert.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center">8 + 3 = 3 + 8</v-row>
                      <v-row no-gutters justify="center">a + b = b + a</v-row>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Multiplikation: </strong>Die Faktoren einer
                      Multiplikationsaufgabe können vertauscht werden, ohne dass
                      sich dabei das Ergebnis ändert.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center">7 · 4 = 4 · 7</v-row>
                      <v-row no-gutters justify="center">a · b = b · a</v-row>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <i
                        >Das <strong>Kommutativgesetz </strong>gilt nicht für
                        die Subtraktion (8 – 4 ist ungleich 4 – 8) und auch
                        nicht für die Division (6 : 3 ist ungleich 3 : 6)</i
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

          <v-col cols="gesetzeTabelleFormat">
            <!-- Assoziativgesetz-Tabelle-->
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr style="text-align: center">
                    <td colspan="2"><strong>Assoziativgesetz</strong></td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Addition: </strong>Die Summanden einer
                      (mehrgliedrigen) Additionsaufgabe können beliebig
                      miteinander verbunden werden.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >2 + 3) + 1 = 2 + (3 + 1)</v-row
                      >
                      <v-row no-gutters justify="center"
                        >(a + b) + c = a + (b + c)</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Multiplikation: </strong>Die Faktoren einer
                      (mehrgliedrigen) Multiplikationsaufgabe können beliebig
                      miteinander verbunden werden.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >(6 · 5) · 7 = 6 · (5 · 7)</v-row
                      >
                      <v-row no-gutters justify="center"
                        >(a · b) · c = a · (b · c)</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <i
                        >Das <strong>Assoziativgesetz </strong>gilt nicht für
                        die Subtraktion, denn (8 – 4) – 3 = 4 – 3 = 1 ist
                        ungleich 8 – (4 – 3) = 8 – 1 = 7 und auch nicht für die
                        Division: (36 : 6) : 3 = 6 : 3 = 2 ist ungleich 36 : (6
                        : 3) = 36 : 2 = 18.</i
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="gesetzeTabelleFormat">
            <!-- Distributivgesetz-Tabelle -->
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr style="text-align: center">
                    <td colspan="2"><strong>Distributivgesetz</strong></td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Addition/ Multiplikation bzw. Division: </strong
                      >Eine Summe wird mit einem Faktor multipliziert (bzw.
                      durch einen Divisor dividiert), indem jeder Summand mit
                      dem Faktor multipliziert (bzw. durch den Divisor
                      dividiert) wird und die Resultate addiert werden.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >6 · 4 = 2 · 4 + 4 · 4
                      </v-row>
                      <v-row no-gutters justify="center"
                        >(a + b) · c = a · c + b · c
                      </v-row>
                      <v-row no-gutters justify="center"
                        >72 ː 8 = 56 ː 8 + 16 ː 8
                      </v-row>
                      <v-row no-gutters justify="center"
                        >(a + b) ː c = a ː c + b ː c</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong
                        >Subtraktion/ Multiplikation bzw. Division: </strong
                      >Eine Differenz wird mit einem Faktor multipliziert (bzw.
                      durch einen Divisor dividiert), indem Minuend und
                      Subtrahend mit dem Faktor multipliziert (bzw. durch den
                      Divisor dividiert) werden und die Resultate voneinander
                      subtrahiert werden.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >7 · 4 = 10 · 4 – 3 · 4</v-row
                      >
                      <v-row no-gutters justify="center"
                        >(a – b) · c = a · c – b · c</v-row
                      >
                      <v-row no-gutters justify="center"
                        >186 ː 6 = 198 ː 6 – 12 ː 6</v-row
                      >
                      <v-row no-gutters justify="center"
                        >(a- b) ː c = a ː c – b ː c</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <i
                        >Analog gilt das <strong>Distributivgesetz </strong>bei
                        der Multiplikation auch, wenn der zweite Faktor
                        „zerlegt“ wird. Die Zerlegung des Divisors funktioniert
                        aber in der Regel nicht.</i
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

          <v-col cols="gesetzeTabelleFormat">
            <!-- Konstanzgesetz-Tabelle -->
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr style="text-align: center">
                    <td colspan="2"><strong>Konstanzgesetz</strong></td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Addition: </strong>Das Ergebnis ändert sich nicht,
                      wenn man eine Zahl vergrößert und die andere entsprechend
                      verkleinert.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center">3 + 5 = 4 + 4</v-row>
                      <v-row no-gutters justify="center"
                        >a + b = (a + n) + (b – n)</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Subtraktion: </strong>Das Ergebnis ändert sich
                      nicht, wenn man beide Zahlen um dieselbe Zahl vergrößert
                      (oder verkleinert)
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center">13 – 8 = 14 – 9</v-row>
                      <v-row no-gutters justify="center"
                        >a – b = (a + n) – (b + n)</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Multiplikation: </strong>Das Ergebnis ändert sich
                      nicht, wenn man eine Zahl multipliziert und die andere
                      entsprechend dividiert.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >18 · 16 = 36 · 8</v-row
                      >
                      <v-row no-gutters justify="center"
                        >a · b = (a · n) · (b ː n)</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Division: </strong>Ergebnis ändert sich nicht,
                      wenn man beide Zahlen durch dieselbe Zahl dividiert (oder
                      entsprechend multipliziert).
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >48 ː 6 = 24 ː 3
                      </v-row>
                      <v-row no-gutters justify="center"
                        >a ː b = (a ː n) ː (b ː n)</v-row
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </div>
      <br />

      <div>
        Bedeutsam sind des Weiteren die Zusammenhänge zwischen den
        Rechenoperationen. Die Multiplikation beispielsweise kann man als
        wiederholte Addition gleicher Summanden verstehen (4 · 7 = 7 + 7 + 7 +
        7). Sie bildet gleichzeitig die Umkehroperation der Division, was die
        Lösung von Divisionsaufgaben mit Hilfe der Multiplikation zu einer
        häufig zu beobachtenden Lösungsstrategie macht (36 : 4 = 9, weil 9 · 4 =
        36). Auch das sind Zusammenhänge die Kinder durchaus erkennen und nutzen
        können.
      </div>
      <br />
      <AppLiteraturOV />
    </v-card>
    <AppBottomNavOV
      back="/operationsverstaendnis/hintergrundwissen/darstellungen"
      next="/operationsverstaendnis/uebergang"
    />
  </div>
</template>

<script>
import AppHelper from "@/common/AppHelper";
import AppLiteraturOV from "@/common/AppLiteraturOV";
import AppButtonCollapse from "@/common/AppButtonCollapse";
import AppBottomNavOV from "@/common/AppBottomNavOV";
import LernbausteinStempeln from "@/components/faledia/explorativeElemente/LernbausteinStempeln";
import LernbausteinStempeln_OABH2_19plus21 from "@/components/faledia/seiten/ove/beispiele/OABH2_19+21";
import LernbausteinStempeln_OABH2_11plus178 from "@/components/faledia/seiten/ove/beispiele/OABH2_11+178";
import LernbausteinStempeln_OABH2_8mal7 from "@/components/faledia/seiten/ove/beispiele/OABH2_8mal7";
import LernbausteinStempeln_OABH2_12mal5 from "@/components/faledia/seiten/ove/beispiele/OABH2_12mal5";
export default {
  components: {
    AppBottomNavOV,
    VuetifyAudio: () => import("vuetify-audio"),
    LernbausteinStempeln,
    AppButtonCollapse,
    AppLiteraturOV,
  },
  data: () => ({
    LBSTStempeln: [
      LernbausteinStempeln_OABH2_19plus21,
      LernbausteinStempeln_OABH2_11plus178,
      LernbausteinStempeln_OABH2_8mal7,
      LernbausteinStempeln_OABH2_12mal5,
    ],
    hidden: true,
  }),
  methods: {
    audioEnded(id) {
      AppHelper.audioEnded(this, id);
    },

    audioPlayerClicked(event, id, ref) {
      AppHelper.audioPlayerClicked(this, event, id, ref);
    },
  },
};
</script>

<style scoped>
#app .no-wrap {
  white-space: nowrap;
  overflow: auto;
}
</style>
